import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const authGuard = (to, from, next) =>{
  if (localStorage.getItem('strapi_jwt')) {
    Vue.prototype.$strapi.fetchUser().then((res) => {
      console.log(res)
      Vue.prototype.$strapi.findOne('users', res.id, {
        populate:"avatar, companies.*.*, companies.contract"
      }).then((res) => {
        console.log(res)
        store.dispatch('setUser', res)
      });
    })
    next()
  } else {
    next('/login')
  }
}


const routes = [
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/cgu',
    name: 'Conditions Générales d\'Utilisation',
    component: () => import('../views/CGUView.vue')
  },
  {
    path: '/politique-confidentialite',
    name: 'Politique de confidentialité',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path: '/ticket',
    redirect: () => {
      return window.innerWidth > 1023? '/ticket-desktop' : '/ticket-mobile';
    }
  },
  {
    path: '/ticket-mobile',
    name: 'TicketView',
    component: () => import('../views/TicketView.vue')
  },
  {
    path: '/resa',
    redirect: (to) => {
      // Vérifie si l'URL contient une sous-route après "/resa"
      const subPath = to.path.split('/resa')[1];
      console.log("subPath :",subPath)
      if (subPath) {
        console.log("==================")
        // Ajoute la sous-route à la redirection
        return window.innerWidth > 1023? `/resa-desktop${subPath}` : `/resa-mobile${subPath}`;
      } else {
        // Redirection sans sous-route
        return window.innerWidth > 1023? '/resa-desktop' : '/resa-mobile';
      }
    }
  },

  {
    path: '/resa-mobile',
    name: 'ResaView',
    component: () => import('../views/ResaView.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'ResaHome',
        component: () => import('@/components/Resa/HomeComponent.vue')
      },
      {
        path: 'calendar',
        name: 'ResaComponent',
        component: () => import('@/components/Resa/ResaComponent.vue')
      },
      {
        path: 'my',
        name: 'MyResaComponent',
        component: () => import('@/views/MyBooking.vue')
      }
    ]
  },
  {
    path: "/imprim",
    name: "ImprimView",
    component: () => import('../views/ImprimView.vue'),
    beforeEnter: authGuard,
  },
  {
    "path": "/forgot-password",
    "name": "ForgotPasswordView",
    "component": () => import('../views/ForgotPasswordView.vue')
  },
  {
    path: '/',
    name: 'DashboardView',
    component: () => import('../views/DashboardView.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'AlmanachView',
        component: () => import('../views/AlmanachView.vue'),
        children: [
          // { path: '', name: 'AlmanachList', component: () => import('@/components/Almanach/List.vue') },
          { path: 'almanach', name: 'AlmanachList', component: () => import('@/components/Almanach/List.vue') },
          { path: '/almanach/:id', name: 'AlmanachSingle', component: () => import('@/components/Almanach/Single.vue') },
          { path: 'darwin-post', name: 'DarwinList', component: () => import('@/components/DarwinPost/List.vue') },
          { path: '/darwin-post/:id', name: 'DarwinSingle', component: () => import('@/components/DarwinPost/Single.vue') },
          { path: 'directory', name: 'DirectoryList', component: () => import('@/components/Contacts/List.vue') },
          { path: '/directory/:id/:type', name: 'DirectorySingle', component: () => import('@/components/Contacts/Single.vue') },
          { path: 'metric', name: 'MetricList', component: () => import('@/components/Metric/List.vue') }
        ]
      },
      {
        path: 'ticket-desktop',
        name: 'TicketView',
        component: () => import('../views/TicketView.vue')
      },
      {
        path: '/resa-desktop',
        name: 'ResaView',
        component: () => import('../views/ResaView.vue'),
        beforeEnter: authGuard,
        children: [
          {
            path: '',
            name: 'ResaHome',
            component: () => import('@/components/Resa/HomeComponent.vue')
          },
          {
            path: 'calendar',
            name: 'ResaComponent',
            component: () => import('@/components/Resa/ResaComponent.vue')
          },
          {
            path: 'my',
            name: 'MyResaComponent',
            component: () => import('@/views/MyBooking.vue')
          }
        ]
      },
    ]
  },
  {
    path: '/wall',
    name: 'WallView',
    component: () => import('../views/WallView.vue'),
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        name: 'Mur d\'échange',
        component: () => import('@/components/Wall/List.vue'),
      },
      {
        path: 'search',
        name: 'Recherche',
        component: () => import('@/components/Wall/Search.vue'),
      },
      {
        path: 'profil',
        name: 'Profil',
        component: () => import('@/components/Wall/Profil.vue'),
      }
    ]
  },
  {
    path:'/user/:id',
    name: 'UserView',
    component: () => import('../views/UserProfil.vue'),
    beforeEnter: authGuard,
  },
  {
    path:'/avantages',
    name: 'AvantagesView',
    component: () => import('../views/AvantagesView.vue'),
    beforeEnter: authGuard,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.path === '/resa')) {
    const isDesktop = window.innerWidth > 1023;
    if (!isDesktop &&!to.path.includes('mobile')) {
      next('/resa-mobile' + to.path);
    } else if (isDesktop &&!to.path.includes('desktop')) {
      next('/resa-desktop' + to.path);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
