import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes:{
            light:{
                primary: '#f5dc14',
                success:'#8BBE65',
                secondary: '#262626',
                white:'#f7f7f7'
            },
        }
    }
});
