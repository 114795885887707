import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// import VueKeycloakJs from '@dsb-norge/vue-keycloak-js'

import axios from 'axios'

Vue.prototype.$axios = axios

Vue.config.productionTip = false
import moment from 'moment'

import Strapi from "strapi-sdk-js";

import VueLoadmore from 'vuejs-loadmore';

Vue.use(VueLoadmore);


Vue.directive('click-outside', {
  bind: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      // Vérifie si le clic est à l'extérieur de l'élément
      if (!(el == event.target || el.contains(event.target))) {
        // Appelle directement la méthode passée en tant que valeur
        if (typeof binding.value === 'function') {
          binding.value(event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});





// Set the base URL for Axios requests using the value from the environment variable VUE_APP_API_URI


console.log('VUE_APP_API_URI', process.env.VUE_APP_API_URI);

// Crée une instance de Strapi avec une URL correctement formatée
const strapi = new Strapi({
  url: process.env.VUE_APP_API_URI,
  store: {
    key: "strapi_jwt",
    useLocalStorage: true,
    cookieOptions: { path: "/" },
  },
});

const apiMetric = axios.create({
  //baseURL: 'http://localhost:1337/api/metriques-zone/proxy/', // Utilise le proxy configuré
  baseURL: 'https://backend-museec.darwin.camp/api/metriques-zone/proxy/', // Utilise le proxy configuré

});

// axios default base url
axios.defaults.baseURL = process.env.VUE_APP_API_URI;


// Fonction pour comparer et recharger si nécessaire
function checkCommitHashAndReload(newCommitHash) {
  // Récupérer l'ancien commit depuis le localStorage
  const oldCommitHash = localStorage.getItem('commitHash');

  // Comparer avec le nouveau hash du commit
  if (oldCommitHash && oldCommitHash !== newCommitHash) {
    // Si le hash a changé, recharger la page
    localStorage.setItem('commitHash', newCommitHash);
    console.log('Nouvelle build: ', oldCommitHash, ' -> ', newCommitHash);
    window.location.reload(true);
  } else {
    // Sinon, stocker le nouveau hash dans le localStorage
    console.log('Build actuelle: ', newCommitHash);
    localStorage.setItem('commitHash', newCommitHash);
  }
}

// Fonction pour récupérer le commit hash depuis l'API
async function fetchCommitHash() {
  try {
    // Appel à l'API pour récupérer le commit hash
    let response = await strapi.find('git-commit-version');
    let lastCommit = response?.data?.attributes?.commitHash;
    // Comparer et recharger si nécessaire
    checkCommitHashAndReload(lastCommit);
  } catch (e) {
    console.error('Erreur lors de la récupération du commit hash:', e);
  }
}

Vue.prototype.$apiMetric = apiMetric;

// Fonction pour récupérer les données par année
async function fetchDataForYear(zoneId, year) {
  try {
    const response = await apiMetric.get(`zone/${zoneId}/${year}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data for year:', error);
    throw error;
  }
}

// Fonction pour récupérer les données par année et mois
async function fetchDataForMonth(zoneId, year, month) {
  try {
    const response = await apiMetric.get(`zone/${zoneId}/${year}/${month}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data for month:', error);
    throw error;
  }
}

async function getAvailableZones() {
  try {
    const response = await apiMetric.get('menu');
    return response.data;
  } catch (error) {
    console.error('Error fetching available zones:', error);
    throw error;
  }
}

// Fonction principale pour récupérer les métriques sans authentification
async function getMetric(zoneId, year, month) {
  try {
    if (!month || month === undefined || month === null || month ===  'undefined') {
      return await fetchDataForYear(zoneId, year);
    } else {
      return await fetchDataForMonth(zoneId, year, month);
    }
  } catch (error) {
    console.error('Error getting metric:', error);
    throw error;
  }
}


const showImage = (image) => {
  // Retourne une image placeholder si aucune image n'est fournie
  if (!image) return "https://via.placeholder.com/300x300?text=No+Image";

  // Construit l'URL de l'image
  const baseUrl = strapi.options.url.endsWith('/') ? strapi.options.url : `${strapi.options.url}/`;
  const imagePath = image.startsWith('/') ? image.substring(1) : image;
  
  // Retourne l'URL complète de l'image
  return `${baseUrl}${imagePath}`;
};


const openLink = (link) => {
  window.open(link, '_blank');
};

Vue.prototype.$openLink = openLink;



// let initOptions = {
//   init: {
//     // Use 'login-required' to always require authentication
//     // If using 'login-required', there is no need for the router guards in router.js
//     onLoad: 'login-required',
//     silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
//   },
//   config: {
//     url: 'https://auth.french-webapps.fr/auth',
//     clientId: 'appvue',
//     realm: 'Darwin'
//   }
// }

// Vue.use(VueKeycloakJs, initOptions)
Vue.prototype.$fetchCommitHash = fetchCommitHash;

Vue.prototype.$getMetric = getMetric;
Vue.prototype.$getAvailableZones = getAvailableZones;

Vue.prototype.$showImage = showImage;

Vue.prototype.$strapi = strapi

moment.locale('fr')
Vue.prototype.$moment = moment
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
